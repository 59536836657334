import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { AboutModule } from './about/about.module';
import { AccountModule } from './account/account.module';
import { ActionsModule } from './actions/actions.module';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { AuditItemsModule } from './audit-items/audit-items.module';
import { AuditsModule } from './audits/audits.module';
import { ContactUsModule } from './contact-us/contact-us.module';
import { ContractorsModule } from './contractors/contractors.module';
import { CoursesModule } from './courses/courses.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DirectivesModule } from './directives/directives.module';
import { EmployeesModule } from './employees/employees.module';
import { ErpModule } from './erps/erp.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HazardousSubstancesModule } from './hazardous-substances/hazardous-substances.module';
import { HazardsModule } from './hazards/hazards.module';
import { IncidentsModule } from './incidents/incidents.module';
import { ErrorInterceptorService } from './error-interceptor.service';
import { ItemsModule } from './items/items.module';
import { MailchimpComponent } from './mailchimp/mailchimp.component';
import { MatComponentsModule } from './mat-components/mat-components.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PipesModule } from './pipes/pipes.module';
import { PrequalificationsModule } from './prequalifications/prequalifications.module';
import { ReportsModule } from './reports/reports.module';
import { UtilsService } from './shared/utils.service';
import { SharedModule } from './shared/shared.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SitesModule } from './sites/sites.module';
import { TaskAnalysesModule } from './task-analyses/task-analyses.module';
import { ToolboxTalksModule } from './toolbox-talks/toolbox-talks.module';
import { TopNavComponent } from './top-nav/top-nav.component';
import { ModalComponent } from './utils/modal/modal.component';
import { ToastComponent } from './utils/toast/toast.component';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { CodesModule } from './admin/codes/codes.module';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { LoginCredentialsModule } from './user/login-credentials/login-credentials.module';
import { FoldersModule } from './folders/folders.module';
import { MyPrequalificationsModule } from './my-prequalifications/my-prequalifications.module';
import { NoauthModule } from './noauth/noauth.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { setAppInjector } from './app-injector';
import { InductionsModule } from './inductions/inductions.module';
import { DefaultAccountModule } from './default-account/default-account.module';
import { AccountsComponent } from './admin/accounts/accounts.component';
import { AccountsEditComponent } from './admin/accounts/accounts-edit/accounts-edit.component';
import { AccountsViewComponent } from './admin/accounts/accounts-view/accounts-view.component';
import { UsersComponent } from './admin/users/users.component';
import { UsersEditComponent } from './admin/users/users-edit/users-edit.component';
import { UsersViewComponent } from './admin/users/users-view/users-view.component';
import { AdminModule } from './admin/admin.module';
import { UserModule } from './user/user.module';
import { MessagesModule } from './messages/messages.module';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { GitlabIssuesModule } from './gitlab-issues/gitlab-issues.module';
import { FileManagerComponent } from './utils/file-manager/file-manager.component';
import { ClipboardModule } from 'ngx-clipboard';
import { HandleQrCodeComponent } from './handle-qr-code/handle-qr-code.component';
import { CodeFeatureAccessControlComponent } from './handle-qr-code/code-feature-access-control/code-feature-access-control.component';
import { SsspModule } from './sssp/sssp.module';
import { SafetyObservationsModule } from './safety-observations/safety-observations.module';
import { UtilsModule } from './utils/utils.module';
import { ToolsModule } from "./tools/tools.module";
import { DynamicFormsCategoriesModule } from './dynamic-forms-categories/dynamic-forms-categories.module';
import { TaskAnalysesRevisedModule } from "./task-analyses-revised/task-analyses-revised.module";
import { DynamicFormsModule } from './dynamic-forms/dynamic-forms.module';
import {VisitorsModule} from "./visitors/visitors.module";
import {ChartsModule} from "./charts/charts.module";
import {IndexedDBModule} from "ng-indexed-db";

@NgModule({
  entryComponents: [
    FileManagerComponent
  ],
  declarations: [
    AppComponent,
    TopNavComponent,
    ModalComponent,
    ToastComponent,
    PageNotFoundComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    MailchimpComponent,
    ReleaseNotesComponent,
    ResetPasswordComponent,
    AccountsComponent,
    AccountsEditComponent,
    AccountsViewComponent,
    UsersComponent,
    UsersEditComponent,
    UsersViewComponent,
    HandleQrCodeComponent,
    CodeFeatureAccessControlComponent
  ],
  imports: [
    // Third-party imports.
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, {
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
}),
    TourMatMenuModule.forRoot(),
    LoadingBarHttpClientModule,
    IndexedDBModule.forRoot([
      {
        name: 'sc_db',
        stores: [
          { name: 'auth' },
          { name: 'charts' }
        ]
      }
    ]),

    // Our modules.
    AdminModule,
    AboutModule,
    AccountModule,
    ActionsModule,
    AuditItemsModule,
    AuditsModule,
    ContactUsModule,
    ContractorsModule,
    CoursesModule,
    DashboardModule,
    DefaultAccountModule,
    DirectivesModule,
    EmployeesModule,
    ErpModule,
    FormsModule,
    HazardousSubstancesModule,
    HazardsModule,
    IncidentsModule,
    ItemsModule,
    ItemsModule,
    MatComponentsModule,
    PipesModule,
    PrequalificationsModule,
    ReportsModule,
    CodesModule,
    SharedModule,
    SitesModule,
    TaskAnalysesModule,
    ToolboxTalksModule,
    MatIconModule,
    MatButtonModule,
    LoginCredentialsModule,
    FoldersModule,
    MyPrequalificationsModule,
    InductionsModule,
    NoauthModule,
    MomentDateModule,
    UserModule,
    MessagesModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    GitlabIssuesModule,
    ClipboardModule,
    SsspModule,
    SafetyObservationsModule,
    UtilsModule,
    ToolsModule,
    TaskAnalysesRevisedModule,
    DynamicFormsModule,
    DynamicFormsCategoriesModule,
    VisitorsModule,
    ChartsModule
  ],
  providers: [
    UtilsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    { // Used to change the display format of dates to 28/02/2020.
      provide: MAT_DATE_FORMATS,
      useValue: {
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    },
    {
      provide: NGX_MAT_DATE_FORMATS, useValue: {
        display: {
          dateInput: 'DD/MM/YYYY HH:mm A',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
