import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { Message } from 'src/app/models/message.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { NetworkedAccountsSelectorComponent } from 'src/app/shared/networked-accounts-selector/networked-accounts-selector.component';
import { NetworkedUsersSelectorComponent } from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { UtilsService } from 'src/app/shared/utils.service';
import * as moment from 'moment';
import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import { CurrentTimezoneStateService } from 'src/app/shared/current-timezone-state.service';

@Component({
  selector: 'app-message-edit',
  templateUrl: './message-edit.component.html',
  styleUrls: ['./message-edit.component.scss']
})
export class MessageEditComponent implements OnInit, OnDestroy {

  message: Message = new Message();
  faHardHat = faHardHat;

  // Used for auto-saving.
  @ViewChild('f') form: NgForm;

  autosaveInterval: any;
  isAutosaving: boolean = false;

  @ViewChild('deferDateTimePicker') deferDateTimePicker: NgxMatDatetimePicker<any>;

  deferDateTime: moment.Moment;

  selectedTimezone: string = this.cTmzState.getCurrentTimezone();

  selectedFiles: File[] = [];

  constructor(
    public app: AppService,
    public api: ApiRequestService,
    public utils: UtilsService,
    private cTmzState: CurrentTimezoneStateService,
    private dialogRef: MatDialogRef<MessageEditComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit() {
    // Get the message id
    const messageId = Number(this.dialogData['messageId']);
    // Validate and make a request to get the message data
    if ( messageId ) {
      this.getMessage(messageId);
    } else {
      // Used for Create From feature
      if(this.dialogData['message']) {
        this.createMessage(this.dialogData['message']);
      }
    }

    // Start the auto-saving interval.
    this.autosaveInterval = setInterval(() => {
      if ( this.form && this.form.valid && this.message.id && this.message.status == 'draft' ) {
        this.isAutosaving = true;
        this.onSave(this.form)
        .finally(() => {
          this.isAutosaving = false;
        });
      }
    }, 15000);

    // Pre-select some accounts
    const preSelectAccountIds = this.dialogData['preSelectAccountIds'];
    if ( preSelectAccountIds && preSelectAccountIds.length > 0 ) {
      this.message.const_accounts_ids = preSelectAccountIds;
    }

    // Pre-select some users
    const preSelectUserIds = this.dialogData['preSelectUserIds'];
    if ( preSelectUserIds && preSelectUserIds.length > 0 ) {
      this.message.const_users_ids = preSelectUserIds;
    }

    // Pre-select some sites
    const preSelectSiteIds = this.dialogData['preSelectSiteIds'];
    if ( preSelectSiteIds && preSelectSiteIds.length > 0 ) {
      this.message.const_sites_ids = preSelectSiteIds;
    }

    // Pre-select some files
    const preSelectFileBlobs = this.dialogData['preSelectFileBlobs'];
    if ( preSelectFileBlobs && preSelectFileBlobs.length > 0 ) {
      this.selectedFiles.push(...preSelectFileBlobs);
    }

    // Pre-select some files
    const preSelectFiles = this.dialogData['preSelectFiles'];
    if ( preSelectFiles && preSelectFiles.length > 0 ) {
      this.message.files.push(...preSelectFiles);
    }

    // Pre-populate the message subject
    const prePopulateSubject = this.dialogData['prePopulateSubject'];
    if ( prePopulateSubject ) {
      this.message.subject = prePopulateSubject;
    }

    // Pre-populate the message
    const prePopulateMessage = this.dialogData['prePopulateMessage'];
    if ( prePopulateMessage ) {
      this.message.message = prePopulateMessage;
    }

    // Associate a safety observation with this message.
    const safetyObservationId = this.dialogData['safetyObservationId'];
    if ( safetyObservationId ) {
      this.message.safety_observation_id = safetyObservationId;
    }
  }

  getOutputTimezone($event) {
    this.selectedTimezone = $event;
  }

  ngOnDestroy() {
    clearInterval(this.autosaveInterval);
  }

  getMessage(messageId: number) {
    this.api.makeRequest('get', `v2/messages/${messageId}`)
    .then((message: Message) => {
      this.message.apply(message);
      // Convert unix time into moment object
      if ( this.message.send_at ) {
        this.deferDateTime = moment.unix(this.message.send_at);
      }
    });
  }

  // Used for Create From feature
  createMessage(message: Message) {
    this.message.apply(message);
    // Convert unix time into moment object
    if ( this.message.send_at ) {
      this.deferDateTime = moment.unix(this.message.send_at);
    }
  }

  // Should return something the dialog can use to dismiss on success.
  // Maybe only on the send button...
  onSave(form: NgForm): Promise<any> {
    // Validate the form inputs
    if ( !form.valid ) {
      this.utils.showFormValidationError('You need to enter a subject and message.');
      return;
    }

    // Get unix date/time from moment
    if ( this.deferDateTime ) {
      const dateToSave = moment.tz(this.deferDateTime.format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', this.selectedTimezone);
      this.message.send_at = dateToSave.unix();
    } else {
      this.message.send_at = null;
    }

    let saveRequest: Promise<any>;

    if ( this.message.id ) {
      // Update an existing message.
      saveRequest = this.api.makeRequest('put', `v2/messages/${this.message.id}`, this.message)
      .then((message: Message): void => {
        // Only apply the content if it is not auto-saving.
        if ( !this.isAutosaving ) {
          this.message.apply(message);
          this.utils.showToast('Your message was updated.');
        }
      })
      .catch((error) => {
        this.utils.handleAPIErrors(error);
      });
    } else {
      // Create a new message.
      saveRequest = this.api.makeRequest('post', `v2/messages`, this.message)
      .then((message: Message) => {
        this.message.apply(message);
      })
      .catch((error) => {
        this.utils.handleAPIErrors(error);
        this.utils.showToast('Your message was saved.');
      });
    }

    return saveRequest.then(() => {
      // Upload any files if the message id is present.
      if ( this.message.id && this.selectedFiles.length > 0 ) {
        return this.api.makeUploadRequest(`v2/file-manager/message/${this.message.id}`, this.selectedFiles)
        .then(() => {
          this.getMessage(this.message.id);
        })
        .finally(() => {
          this.selectedFiles.length = 0;
        });
      }
    });
  }

  canEditMessage() {
    return ['draft', 'cancelled'].indexOf(this.message.status) > -1;
  }

  /**
   * Checks if a message can be queued for sending.
   *
   * @return {boolean} Returns true if the message can be queued, false otherwise.
   */
  canQueueMessage() {
    return this.form && this.form.valid &&
    (
      this.message.const_sites_ids.length > 0 ||
      this.message.const_accounts_ids.length > 0 ||
      this.message.const_users_ids.length > 0
    ) &&
    this.message.status == 'draft' &&
    this.message.id;
  }

  canDequeueMessage() {
    return this.message.status == 'queued' &&
    this.message.id;
  }

  onQueueMessage() {
    if ( !this.canQueueMessage() ) {
      this.utils.showModal('Queueing Message Failed', 'The message cannot be queued. Please make sure you saved the message and added at least 1 ' + this.utils.getLangTerm('parent-child-sites-combined.singular', 'Site') + ', 1 ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' or 1 user.');
      return;
    }

    // Change the message status to queued in order to disable autosaving.
    this.message.status = 'queued';

    // Save before queueing...
    this.api.makeRequest('put', `v2/messages/${this.message.id}/queue`)
    .then((response: Message) => {
      this.message.apply(response);

      this.utils.showToast('Your message is queued and will be processed soon.');

      // Respond with true if the dialog was successfully closed.
      this.dialogRef.close(true);
    })
    .catch((errors) => {
      this.utils.handleAPIErrors(errors);
      // Should the queue request fail, change it back to draft.
      this.message.status = 'draft';
    });
  }

  onDequeueMessage() {
    if ( !this.canDequeueMessage() ) {
      this.utils.showModal('Dequeueing Message Failed', 'The message cannot be dequeued. Please save your changes and refresh the page if needed.');
      return;
    }
    this.api.makeRequest('put', `v2/messages/${this.message.id}/dequeue`)
    .then((response: Message) => {
      this.message.apply(response);

      this.utils.showToast('Your message is dequeued and will no longer be processed.');
    })
    .catch((errors) => {
      this.utils.handleAPIErrors(errors);
    });
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.message.const_sites_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (selectedIds: number[]) => {
        if (typeof selectedIds != 'undefined') {
          this.message.const_sites_ids = selectedIds;
        }
      }
    );
  }

  onSelectAccounts() {
    this.utils.showComponentDialog(
      NetworkedAccountsSelectorComponent,
      {
        selected: this.message.const_accounts_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (selectedIds: number[]) => {
        if (typeof selectedIds != 'undefined') {
          this.message.const_accounts_ids = selectedIds;
        }
      }
    );
  }

  onSelectUsers() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.message.const_users_ids, // Todo
      multiple: true,
      selectedAccountId: this.app.account.id,
      visitors_from_all_sites: true
    }, {
      width: '1024px'
    },
    (selectedIds: number[]) => {
      if (typeof selectedIds != 'undefined') {
        this.message.const_users_ids = selectedIds;
      }
    });
  }

  onSelectFiles(files?: any[]) {
    if ( files.length > 0 ) {
      this.selectedFiles.length = 0;
      this.selectedFiles.push(...files);
    }
  }

  getMessageCharacterCount() {
    return this.message.message.replace(/(<([^>]+)>)/gi, '').replace(/\&nbsp;/gi, ' ').length;
  }

  getMessageWordCount() {
    const wordCounter = this.message.message.replace(/(<([^>]+)>)/gi, '').replace(/\&nbsp;/gi, ' ').match(/\S+/g);
    return wordCounter ? wordCounter.length : 0;
  }

}
