<div mat-dialog-title class="position-relative w-100">
  <h5>Filter Tasks</h5>

  <button mat-icon-button class="position-absolute" matTooltip="Close Filter Tasks" mat-dialog-close style="top: -10px;right: 0">
    <mat-icon color="secondary">close</mat-icon>
  </button>
</div>

<div class="d-flex my-2 py-2 flex-wrap" style="justify-content: space-evenly">

  <ng-container matColumnDef="users">
    <div style="padding: 5px !important;">
      <button
        mat-raised-button
        type="button"
        (click)="onSelectUsers()"
        [matBadge]="'' + (dataSource.selected_assigned_users.length || 'All')"
        class="mx-3 mt-3"
        color="">
        <mat-icon>account_circle</mat-icon> Assigned Users
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="priority">
    <div style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-2" style="width: 100px;">
        <mat-select placeholder="Priority" [(ngModel)]="dataSource.filter_priority"
                    name="priority"
                    matTooltip="Filter records by Priority">
          <mat-option value="">All</mat-option>
          <mat-option *ngFor="let priority of priorities" [value]="priority">
            {{priority}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container matColumnDef="status">
    <div style="padding: 5px !important;">
      <mat-form-field class="mx-3 mt-2" style="width: 100px;">
        <mat-select placeholder="Status" [(ngModel)]="dataSource.filter_status"
                    name="status"
                    matTooltip="Filter records by Status">
          <mat-option value="">All</mat-option>
          <mat-option value="0">Open</mat-option>
          <mat-option value="1">Completed</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <app-date-range-picker dateRangeTitle="Due Date" dateRangePlaceholder="Select Due Date Range" dateRangeTooltip="Filter Tasks Due Date by date range." [dateRangeInput]="dataSource.due_date_range" (dateRangeOutput)="getOutputDateRange($event)"></app-date-range-picker>

</div>

<div class="d-flex justify-content-center my-2 py-2">
  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        class="float-right"
        color="primary"
        (click)="onSaveAndClose()">
        <mat-icon>filter_list</mat-icon> Apply Filter
      </button>
    </div>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <div class="p-2">
      <button
        type="button"
        mat-raised-button
        color="secondary"
        class="float-right"
        (click)="onClearAndClose()">
        <mat-icon> clear_all</mat-icon> Clear Filter
      </button>
    </div>
  </ng-container>
</div>
