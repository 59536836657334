import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiDataSource } from 'src/app/utils/api-data-source';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppService } from 'src/app/app.service';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { tap } from 'rxjs/operators';
import { AccountsMergerComponent } from './accounts-merger/accounts-merger.component';
import { MessageEditComponent } from 'src/app/messages/message-edit/message-edit.component';
import {AccountsFilterComponent} from "./accounts-filter/accounts-filter.component";
import {AccountModel} from "../../models/account.model";

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

  displayedColumns: string[] = [
    'select',
    'id',
    'name',
    'subscription',
    'enable_sms_notifications',
    'date_created',
    'settings',
    'actions'
  ];

  dataSource = new AccountsDataSource(this.app, this.api);

  // the paginator and sorter
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService
  ) { }

  ngOnInit() {
    this.dataSource.getData(true);
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.order_by = "date_created";
      } else {
        this.dataSource.order_by = this.sort.active;
      }


      this.dataSource.getData();
    });

    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onMergeSelected() {
    const selectedAccountIds = this.dataSource.selection.selected;
    if (selectedAccountIds.length < 2) {
      this.utils.showModal('Cannot Merge', 'You must select at least two accounts to merge.');
      return;
    }

    this.utils.showComponentDialog(AccountsMergerComponent, {
      selectedAccountIds: selectedAccountIds
    }, {}, () => {
      this.dataSource.getData();
      this.dataSource.deselectAll();
    });
  }

  onCombinePreQualifications(account_id: number) {
    this.utils.showModal('Confirmation', 'Are you sure you want to combine master/slave account ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' Pre-Qualifications?', () => {
      this.api.makeRequest('put', 'v2/admin/accounts/combine-slave-pre-qualifications', {
        account_id: account_id
      })
        .then((response) => {
          this.utils.showToast('The Pre-Qualifications were combined.');
        });
    });
  }

  composeNewMessage(accountId: number = null) {
    if (this.dataSource.selection.selected.length == 0 && !accountId) {
      this.utils.showToast('You need to select some accounts first.');
      return; // early termination
    }

    this.utils.showModal('Sending Messages', 'You are about to send the message on behalf of ' + this.app.account.name + '. Switch to a different account if this is not intended.');

    this.utils.showComponentDialog(MessageEditComponent, {
      preSelectUserIds: accountId ? [accountId] : this.dataSource.selection.selected
    })
      .then(() => {
        this.utils.showToast('You can view the message stats in the messages section.');
      });
  }

  /**
   * Returns the tooltip text based on the given account settings record.
   *
   * @param {AccountModel} record - The account settings record.
   * @returns {string} - The tooltip text.
   */
  getSettingsTooltip(record: AccountModel): string {
    const tooltips: string[] = [];
    const createTooltip = (condition: boolean, message: string) => condition ? `${message} is enabled.` : `${message} is disabled.`;

    tooltips.push(createTooltip(record.allow_auto_site_signout, 'Auto sign-outs'));
    tooltips.push(createTooltip(record.enable_site_sign_in_ack_reminders, 'Risk acknowledgement reminders at sign-in'));
    tooltips.push(createTooltip(record.enable_sms_notifications, 'SMS messaging'));
    tooltips.push(createTooltip(record.hazard_verification, 'Hazards verification'));
    tooltips.push(createTooltip(record.include_in_search_results, 'Include in search results'));
    tooltips.push(createTooltip(record.induction_notifications_enabled, 'Induction notifications'));
    tooltips.push(createTooltip(record.is_logbook, 'Digital Logbook'));
    tooltips.push(createTooltip(record.pandemic_enabled, 'Covid-19 module'));
    tooltips.push(createTooltip(record.site_arrival_notifications_enabled, 'Sign-in arrival notifications'));
    tooltips.push(createTooltip(record.include_main_contact_in_notifications, 'Including the main account contact in all notifications'));

    return '- ' + tooltips.join("\n- ");
  }

  /**
   * Open the list filters.
   */
  onOpenFilters() {
    this.utils.showComponentDialog(AccountsFilterComponent, {
      subscription: this.dataSource.subscription,
      include_merged_accounts: this.dataSource.include_merged_accounts
    }, {
      width: '350px'
    })
      .then((response) => {
        if ( response ) {
          this.dataSource.subscription = response.subscription;
          this.dataSource.include_merged_accounts = response.include_merged_accounts;
          this.dataSource.getData(true);
        }
      });
  }

}

export class AccountsDataSource extends ApiDataSource {

  order_by = 'id';
  order = 'asc';

  subscription: string = '';
  include_merged_accounts: boolean = false;

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/admin/accounts', resetOffset, {
      subscription: this.subscription,
      include_merged_accounts: this.include_merged_accounts
    });
  }
}
