import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuditsModel } from '../audits.model';
import { AuditsService } from '../audits.service';
import { MatDialog } from '@angular/material/dialog';
import { AuditItemModel } from 'src/app/audit-items/audit-item.model';
import { AuditsViewItemsComponent } from './audits-view-items/audits-view-items.component';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { ContractorsDetailsComponent } from 'src/app/contractors/contractors-details/contractors-details.component'; // This should change to a view component.
import { AccountModel } from 'src/app/models/account.model';
import {UserPublicProfileComponent} from "../../shared/user-public-profile/user-public-profile.component";
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-audits-view',
  templateUrl: './audits-view.component.html',
  styleUrls: ['./audits-view.component.scss']
})
export class AuditsViewComponent implements OnInit {
  audit = new AuditsModel();
  displayedColumns = [
    'id',
    'name',
    'status',
    'date_created',
    // 'date_created_UTC',
    'date_modified',
    // 'date_modified_UTC',
    'actions'
  ];
  displayedContractorColumns = ['name', 'industry', 'address', 'actions'];
  displayedContactsColumns = ['contact_name', 'email', 'user_status'];

  contactDataSource;
  auditId:number;

  /**
   * A unique list of sections.
   */
  sections: string[] = [];

  /**
   * Audit items grouped by section.
   */
  sectionsItems: {[section: string]: AuditItemModel[]} = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private auditsService: AuditsService,
    private dialog: MatDialog,
    public utils: UtilsService
  ) {}

  ngOnInit() {
    this.auditId = Number(this.activatedRoute.snapshot.params['id']);
    this.auditsService
      .find(this.auditId)
      .subscribe((response) => {
        this.audit = response.data;

        this.audit.items.forEach((item) => {
          if ( this.sections.indexOf(item.section) === -1 ) {
            this.sections.push(item.section);
          }

          if ( !this.sectionsItems[item.section] ) {
            this.sectionsItems[item.section] = [];
          }
          this.sectionsItems[item.section].push(item);
        });

      });
      this.getAuditContactsApi()
  }

  getAuditContactsApi(page?){
    this.auditsService.getContacts(this.auditId, page).subscribe(res=>{
      this.contactDataSource = res.data
    })
  }

  onOpenUrl(url: string) {
    this.utils.openUrl(url);
  }

  async downloadPdf() {
    this.utils.showToast('You can either wait or continue using the application ' +
    'while you wait for the Inspection/Audit report. You will automatically be redirected to it once it is finished.');

    // get the url of the exported doc instead
    this.api.laravelApiRequest('get', `audits/export/${this.audit.id}`, {}, {}, (response) => {
      const link = document.createElement('a');
      link.href = response.data.url;
      link.target = '_blank';
      link.download = 'inspections-audits-report.pdf';
      link.click();
    }, (errorResponse) => {
      this.utils.showModal('Failed to get export', 'We could not get you your Inspection/Audit export. Please try again.');
    });

  }

  view(item: AuditItemModel) {
    this.utils.showComponentDialog(AuditsViewItemsComponent, item, { width: '700px' });
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
      }
    );
  }

  handlePageEvent(e) {
    this.getAuditContactsApi(e.pageIndex + 1)
  }
}
