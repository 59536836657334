import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskAnalysisModel } from 'src/app/models/task-analysis.model';
import { ApiService } from 'src/app/shared/api.service';
import { UtilsService } from 'src/app/shared/utils.service';
import { FileService } from 'src/app/shared/file.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';

@Component({
  selector: 'app-task-analyses-edit',
  templateUrl: './task-analyses-edit.component.html',
  styleUrls: ['./task-analyses-edit.component.scss']
})
export class TaskAnalysesEditComponent implements OnInit {
  task_analysis: TaskAnalysisModel = new TaskAnalysisModel();

  dialogData: any;

  newFiles: FileList;

  inProgress: boolean = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public renderer: Renderer2,
    public utils: UtilsService,
    private api: ApiService,
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      sssp_id: number;
      task_analysis_id: number;
      site_id?: number;
    },
    private dialogRef: MatDialogRef<TaskAnalysesEditComponent>
  ) {
    this.task_analysis.id = data.task_analysis_id;
    if (data.site_id) { this.task_analysis.site_ids = [data.site_id]; }
    if (!isNaN(data.sssp_id)) { this.task_analysis.sssp_ids.push(data.sssp_id); }
  }

  ngOnInit() {
    if (isNaN(this.task_analysis.id)) { return; }
    this.find();
  }

  find() {
    this.api.laravelApiRequest(
      'get',
      `task-analysis/${this.task_analysis.id}`,
      {},
      {},
      (response) => {
        this.task_analysis.apply(response.data);
        this.task_analysis.site_ids = this.task_analysis.sites.map(
          (site) => site.id
        );
      }
    );
  }

  onRemoveFiles() {
    this.utils.showComponentDialog(
      FilesRemoveComponent,
      this.task_analysis.files,
      {
        width: '700px'
      },
      () => this.find()
    );
  }

  async onSubmit(form: NgForm, callback?: any) {
    if (!form.valid) {
      this.utils.showModal(
        'Validation Error',
        'Please make sure you enter all fields with valid information.'
      );
      return;
    }

    if ( this.inProgress ) {
      return;
    }

    this.inProgress = true;

    // try to upload the files and handle the response gracefully.
    try {
      this.task_analysis.files = await this.fileService.createManyFromForm(this.newFiles);
    } catch (error) {
      // Reset the in progress status.
      this.inProgress = false;
      return;
    }

    if (isNaN(this.task_analysis.id)) {
      this.create();
    } else {
      this.update();
    }

  }

  create() {
    this.api.laravelApiRequest(
      'post',
      'task-analysis',
      this.task_analysis,
      {},
      (response) => {
        this.task_analysis.id = response.data.id;

        this.inProgress = false;

        if ( this.dialogRef ) {
          this.dialogRef.close(true);
        } else {
          this.router.navigate(['/task-analysis']);
        }
      },
      () => {
        this.inProgress = false;
      }
    );
  }

  update() {
    this.api.laravelApiRequest(
      'put',
      'task-analysis/' + this.task_analysis.id,
      this.task_analysis,
      {},
      () => {
        this.utils.showToast('The Task Analysis Document was updated.');
        this.inProgress = false;
        this.newFiles = null;
        this.find();
      },
      () => {
        this.inProgress = false;
      }
    );
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.task_analysis.site_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results: number[]) => {
        if (typeof results === 'undefined') { return; }
        this.task_analysis.site_ids = results;
      }
    );
  }

  onRemove() {
    this.utils.showModal(
      'Remove Task Analysis Document',
      'Are you sure you want to remove this Task Analysis Document?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'task-analysis/' + this.task_analysis.id,
          {},
          {},
          () => {
            this.utils.showToast('The Task Analysis Document was removed.');
            this.dialogRef.close(true);
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }
}
