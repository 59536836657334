// Moved the AppTitle/AppName to environment files.

export const site_types: string[] = [
  'Office',
  'Studio',
  'Sound Stage',
  'Back Lot',
  'On-Location',
  'Remote Location'
];

export const site_statuses: string[] = [
  'Development',
  'Prep',
  'Pre-Production',
  'Production',
  'Wrap Production',
  'Post-Production',
  'Complete/Archived',
  'On Hold'
];

