import { Auditable } from "../shared/auditable.model";
import { UserModel } from "./user.model";
import { NotificationRole } from '../interfaces/notification-role.interface';

export class AccountModel extends Auditable {

  id = 0;
  name = '';
  industry = 'Other Services';
  subscription: 'Free' | 'Basic' | 'Advanced' | 'Premium' = 'Free';
  permissions = 'Employee';

  address = '';
  suburb = '';
  city = '';
  postal_code = '';
  country = '';
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  is_logbook: boolean = false;

  hazard_verification: boolean = false;
  enable_sms_notifications: boolean = false;

  // This is used to identify the selected (active) account as a slave account.
  master_account_id: number = null; // If 0 or NULL, it is a master, if any other positive number, it is a slave.
  master_account: AccountModel = null;

  users: UserModel[] = [];

  pandemic_enabled: boolean = false;
  pandemic_sign_in_declaration: string = '';
  pandemic_sign_out_declaration: string = '';

  site_arrival_notifications_enabled: boolean = false;

  pandemic_auto_gps_sign_outs_enabled: boolean = false;

  contractor_of: AccountModel[] = [];

  company_logo_url: string = '';

  sssp_header: string = '';

  include_in_search_results: boolean = false;

  /**
   * Main Contact that belongs to Account?
   */
  main_contact: UserModel = null;
  main_contact_id: number = 0;

  // Include the main account contact in all notifications.
  include_main_contact_in_notifications: boolean = false;

  // Enable auto system sign-outs for sites.
  allow_auto_site_signout: boolean = false;

  // Enable risk acknowledgement sign in reminders.
  enable_site_sign_in_ack_reminders: boolean = false;

  // Enable induction notifications.
  induction_notifications_enabled: boolean = false;

  // Notification roles for the account.
  notification_roles: NotificationRole[] = [];

  apply(data: any) {
    if ( data ) {
      Object.keys(data).forEach((key) => {
        // do not store unwanted data
        if (key in this) {
          switch ( key ) {
            case 'contractor_of':
              data[key].forEach((contractor: AccountModel) => {
                this[key].push(contractor);
              });
              break;
            default:
              this[key] = data[key];
          }
        }
      });
    }

    return this;
  }

  isMasterAccount() {
    return !(this.master_account_id > 0);
  }

  reset(): void {
    this.id = 0;
    this.name = '';
    this.industry = 'Other Services';
    this.subscription = 'Free';
    this.permissions = 'Employee';

    this.address = '';
    this.suburb = '';
    this.city = '';
    this.postal_code = '';
    this.country = '';

    this.master_account_id = 0;

    this.contractor_of.length = 0;

    this.company_logo_url = '';

    this.sssp_header = '';

    this.include_in_search_results = false;

    this.main_contact = null;
    this.main_contact_id = 0;

    this.include_main_contact_in_notifications = true;

    this.notification_roles.length = 0;
  }
}
