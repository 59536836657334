import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ToolboxTalkModel } from 'src/app/models/toolbox-talk.model';
import { ApiService } from 'src/app/shared/api.service';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { UtilsService } from '../../shared/utils.service';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';
import { FileService } from 'src/app/shared/file.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToolboxTalksEditDialogComponent } from '../toolbox-talks-edit-dialog/toolbox-talks-edit-dialog.component';
import {
  NetworkedUsersSelectorComponent
} from 'src/app/shared/networked-users-selector/networked-users-selector.component';
import { UserModel } from 'src/app/models/user.model';
import { ApiRequestService } from 'src/app/shared/api-request.service';

declare var tinymce: any;

@Component({
  selector: 'app-toolbox-talks-edit',
  templateUrl: './toolbox-talks-edit.component.html',
  styleUrls: ['./toolbox-talks-edit.component.scss']
})
export class ToolboxTalksEditComponent implements OnInit {

  toolbox_talk: ToolboxTalkModel = new ToolboxTalkModel();

  newFiles: FileList;

  inProgress: boolean = false;

  assignee: UserModel = {} as UserModel;

  site_id: number = 0;

  constructor(
    public app: AppService,
    private oldApi: ApiService,
    private api: ApiRequestService,
    public router: Router,
    public renderer: Renderer2,
    public utils: UtilsService,
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialog: MatDialogRef<ToolboxTalksEditComponent | ToolboxTalksEditDialogComponent>
  ) {
  }

  ngOnInit() {
    if (this.dialogData.id && (typeof this.dialogData.id == 'number')) {
      this.find();
    } else {
      // If the toolbox talk data is present, the "Create From" button was pressed.
      if( this.dialogData.toolbox_talk ) {
        this.toolbox_talk.apply(this.dialogData.toolbox_talk);
      }

      // Store the site id for all new toolbox talks.
      this.toolbox_talk.site_id = this.dialogData.site_id; // The site id must always be present.
    }
  }

  ngAfterViewInit() {
    tinymce.execCommand('mceAddControl', false, 'toolbox_talks_agenda');
  }

  onRemoveFiles() {
    this.utils.showComponentDialog(
      FilesRemoveComponent,
      this.toolbox_talk.files,
      {
        width: '700px'
      },
      () => this.find()
    );
  }

  async onSubmit(form: NgForm, continueWhenDone: boolean = false) {
    // make sure the form data is valid
    if (form.valid) {

      if ( this.inProgress ) {
        return;
      }

      this.inProgress = true;

      // Only set the site id if this is a new toolbox talk.
      if ( !this.toolbox_talk.id ) {
        this.toolbox_talk.site_id = this.dialogData.site_id ? this.dialogData.site_id : 0;
      }

      this.toolbox_talk.agenda = this.toolbox_talk.agenda.replace(/><li[^>]*>/g, ">\n<li>")

      const toolboxCopy = { ...this.toolbox_talk };

      // try to upload the files and handle the response gracefully.
      try {
        toolboxCopy.files = await this.fileService.createManyFromForm(this.newFiles);
      } catch (error) {
        // Reset the in progress status.
        this.inProgress = false;
        return;
      }

      // check if the site should be created or updated
      if (this.toolbox_talk.id) {
        this.update(toolboxCopy as ToolboxTalkModel, continueWhenDone);
      } else {
        this.create(toolboxCopy as ToolboxTalkModel);
      }
    } else {
      alert('Please make sure you enter all fields with valid information.');
    }
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.toolbox_talk.selected_site_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.toolbox_talk.selected_site_ids = results;
        }
      }
    );
  }

  /**
   * Make a user selection for Toolbox Talk attendees.
   */
  onSelectUsers() {
    this.utils.showComponentDialog(NetworkedUsersSelectorComponent, {
      selected: this.toolbox_talk.user_ids,
      multiple: true,
      visitor_site_id: this.toolbox_talk.site_id
    }, {
      width: '1024px'
    }, (results: number[]) => {
      if (!results) { return; }
      this.toolbox_talk.user_ids = results;
    });
  }

  /**
   * Make a user selection for Toolbox Talk reported by users.
   */
  onSelectReportedBy() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: this.toolbox_talk.reported_by ? [this.toolbox_talk.reported_by] : [],
        visitor_site_id: this.toolbox_talk.site_id
      },
      {},
      (userId: number) => {
        if (!userId) { return; }
        this.toolbox_talk.reported_by = userId;
      }
    );
  }

  onRemove() {
    this.utils.showModal(
      'Remove ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk'),
      'Are you sure you want to remove this ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + '?',
      () => {
        this.oldApi.laravelApiRequest(
          'delete',
          'toolbox-talks/' + this.toolbox_talk.id,
          {},
          {},
          () => {
            this.utils.showToast('The ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' was removed');
            this.dialog.close();
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  private find() {
    this.oldApi.laravelApiRequest(
      'get',
      'toolbox-talks/' + this.dialogData.id,
      {},
      {},
      (response) => {
        this.toolbox_talk.apply(response.data); // Apply the data instead of overriding the object.
        this.toolbox_talk.user_ids = this.toolbox_talk.users.map(
          (user) => user.id
        );
      },
      (error) => {
        this.utils.showModal('Error', error.message);
      }
    );
  }

  private create(toolboxTalk: ToolboxTalkModel, callback?: any) {
    this.oldApi.laravelApiRequest(
      'post',
      'toolbox-talks',
      toolboxTalk,
      {},
      (response) => {
        this.utils.showToast('The ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' was created.');

        this.toolbox_talk.id = response.data.id;

        this.inProgress = false;

        if (typeof callback === 'function') {
          callback();
        }

        this.dialog.close(true);
      },
      (error) => {
        this.utils.showModal('Error', error.message);
        this.inProgress = false;
      }
    );
  }

  private update(toolboxTalk: ToolboxTalkModel, continueWhenDone: boolean = false) {
    this.oldApi.laravelApiRequest(
      'put',
      'toolbox-talks/' + toolboxTalk.id,
      toolboxTalk,
      {},
      () => {
        this.utils.showToast('The ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + ' was updated.');
        this.inProgress = false;
        this.newFiles = null;
        if(continueWhenDone) {
          this.dialog.close();
          if(this.dialogData.redirect_url && this.dialogData.redirect_url != '') {
            this.router.navigate([this.dialogData.redirect_url]);
          } else {
            this.router.navigate([`/toolbox-talks/${toolboxTalk.id}/view/`]);
          }
        } else {
          this.find();
        }
      },
      (error) => {
        this.utils.showModal('Error', error.message);
        this.inProgress = false;
      }
    );
  }

  formatUsers(users: UserModel[]): string {
    return users.map((user) => user.contact_person).join(', ');
  }

  onExport(type: string = 'pdf') {
    if ( !this.toolbox_talk.id || !this.toolbox_talk.site_id ) {
      this.utils.showModal('Error Message', 'We are unable to export the ' + this.utils.getLangTerm('toolbox-talks.singular', 'Toolbox Talk') + '. Please try again or contact us at ' + this.utils.getSupportEmailAddress() + ' for assistance.');
      return;
    }

    this.api.makeDownloadRequest(`v1/toolbox-talks/export/${type}/` + this.toolbox_talk.id, {}, {
      site_ids: [this.toolbox_talk.site_id]
    })
    .then((response) => {
      if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
        window.navigator.msSaveOrOpenBlob(response);
        return;
      }

      // Get the current date object
      const date = new Date();

      // Create object url to handle file downloads
      const data = window.URL.createObjectURL(response);

      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = data;
      downloadLink.download = (this.utils.getLangTerm('toolbox-talks.plural', 'Toolbox Talks')) + ` - ${date.getFullYear()}${(date.getMonth()+1)}${date.getDate()}.${type}`;
      // Initiate the download
      downloadLink.click();

      // For Firefox it is necessary to delay revoking the ObjectURL
      setTimeout(function() {
        window.URL.revokeObjectURL(data);
      }, 300); // Minimum 300 miliseconds
    })
    .catch((errorResponse) => {
      this.utils.showModal('Error', errorResponse.message);
    });
  }

}
