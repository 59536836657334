import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ApiDataSource } from "../utils/api-data-source";
import { UtilsService } from "../shared/utils.service";
import { AppService } from "../app.service";
import { ApiRequestService } from "../shared/api-request.service";
import { tap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ToolModel } from "./tools.model";
import { ToolsEditComponent } from "./tools-edit/tools-edit.component";
import { ToolsViewComponent } from "./tools-view/tools-view.component";
import { ToolsFilterComponent } from "./tools-filter/tools-filter.component";
import {UserPublicProfileComponent} from "../shared/user-public-profile/user-public-profile.component";

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new ToolsDataSource(this.app, this.api);

  @ViewChild('fileInput') fileInput: ElementRef;

  displayedColumns = [
    'select',
    'id',
    'title',
    'industry',
    'expires_at',
    'maintenance_safety_checks',
    'created_by_user',
    'date_created',
    // 'date_created_UTC',
    'buttons'
  ];

  constructor(
    private utils: UtilsService,
    private app: AppService,
    private api: ApiRequestService,
    private dialog: MatDialog,
    public renderer: Renderer2
  ) { }

  ngOnInit() {
    this.dataSource.getData(false);
  }

  ngAfterViewInit() {
    // Reset the paginator when sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const _tap = tap(() => {
      this.dataSource.limit = this.paginator.pageSize;
      this.dataSource.offset = this.paginator.pageIndex;
      this.dataSource.order = this.sort.direction;

      // sorting for utc time by parsing original time
      if (this.sort.active === "date_created_UTC") {
        this.dataSource.order_by = "date_created";
      } else {
        this.dataSource.order_by = this.sort.active;
      }


      this.dataSource.getData(false);
    });

    // Subscribe to the paginator tap events
    this.paginator.page.pipe(_tap).subscribe();
    this.sort.sortChange.pipe(_tap).subscribe();
  }

  onAdd() {
    this.onEdit(new ToolModel());
  }

  onEdit(tool: ToolModel) {
    this.dialog
      .open(ToolsEditComponent, {
        width: '900px',
        data: tool
      })
      .afterClosed()
      .subscribe(() => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      });
  }

  onView(id: number) {
    this.utils.showComponentDialog(
      ToolsViewComponent,
      id,
      { width: '700px' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        this.dataSource.getData(true);
      }
    );
  }

  onRemoveSelected() {
    this.utils.showModal(
      'Archive Tool(s)',
      `Are you sure you want to archive ${this.dataSource.selection.selected.length} tool(s)?`,
      () => {
        this.remove(this.dataSource.selection.selected);
      }
    );
  }

  onRemove(tool: ToolModel) {
    this.utils.showModal(
      'Archive Tool',
      `Are you sure you want to archive "${tool.title}"?`,
      () => {
        this.remove([tool.id]);
      }
    );
  }

  private remove(ids: number[]) {
    this.api.makeRequest('delete', `v2/tools/${ids.join(',')}`)
      .then((response) => {
        this.utils.showToast('Tools have been archived');
        this.dataSource.selection.clear();
        this.dataSource.getData(false);
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  /**
   * Show component dialog for filtering tools.
   *
   * @return {void}
   */
  onFilter(): void {
    this.utils.showComponentDialog(ToolsFilterComponent, {
        selected_industry_id: this.dataSource.industryFilter,
        filter_archived: this.dataSource.archived
      }, {
        width: '768px'
      },
      (results: any): void => {
        // Terminate early if it is undefined or an empty string.
        if ( typeof results === 'undefined' || results === '' ) {
          return;
        }

        // Apply the filters.
        this.dataSource.industryFilter = results.industry_id;
        this.dataSource.archived = results.archived ? results.archived : this.dataSource.archived;
        this.dataSource.getData();
      }
    );
  }

  onRestore(id: number) {
    this.utils.showModal(
      'Restore Tool',
      'Are you sure you want to restore this tool?',
      () => {
        this.api.makeRequest('put', `v2/tools/${id}/restore`)
          .then((response) => {
            this.utils.showToast('The tool was restored.');
            this.dataSource.selection.deselect(id);
            this.dataSource.getData();
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  onRestoreSelected() {
    this.utils.showModal(
      'Restore Selected Tools',
      'Are you sure you want to restore the selected tools?',
      () => {
        this.api.makeRequest('put', `v2/tools/${this.dataSource.selection.selected.join(',')}/restore`)
          .then((response) => {
            this.utils.showToast('The selected tools was restored.');
            this.dataSource.selection.clear();
            this.dataSource.getData();
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      }
    );
  }

  onTriggerFileSelector(evt: Event) {
    if(this.fileInput) {
      const clickEvt: MouseEvent = new MouseEvent('click', { bubbles: true });
      this.fileInput.nativeElement.dispatchEvent(clickEvt);
    }
  }

  /**
   * Exports the list of tools into the specified format and sends a download request to the browser.
   * @param type csv, xls, xlsx, pdf.
   */
  onExportSelected(type: string = 'csv') {
    this.dataSource.makeDownloadRequest(`v2/tools/export/${type}` + (this.dataSource.selection.selected.length ? '/' + this.dataSource.selection.selected.join(',') : ''), {
      searchBy: this.dataSource.searchBy,
      industryFilter: this.dataSource.industryFilter,
      archived: this.dataSource.archived
    })
      .then((response) => {
        // For IE and Edge browsers.
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response);
          return;
        }

        // Get the current date object
        const date = new Date();

        // Create object url to handle file downloads
        const data = window.URL.createObjectURL(response);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = data;
        downloadLink.download = `tools-${date.getFullYear()}${(date.getMonth() + 1)}${date.getDate()}.${type}`;
        // Initiate the download
        downloadLink.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 300); // Minimum 300 miliseconds
      })
      .catch((errorResponse) => {
        this.utils.handleAPIErrors(errorResponse);
      });
  }

  onImport(evt: any) {
    if (evt.target.files.length > 0) {

      const files: any = [];
      for (let i = 0; i < evt.target.files.length; i++) {
        files.push(evt.target.files[i]);
      }

      this.utils.showModal('Import Tools', 'Are you sure you want to import the tools from the selected spreadsheet? It is recommended to use the same format as the tool exports. You will need to remove any duplicated tools from the list after importing.', () => {
        this.api.makeUploadRequest(`v2/tools/import`, files)
          .then((response) => {
            this.utils.showToast(response);
            this.dataSource.getData(true);
          })
          .catch((errorResponse) => {
            this.utils.handleAPIErrors(errorResponse);
          });
      });

      // clear the target value
      evt.target.value = '';
    }
  }

  onUserPublicView(hash: string) {
    this.utils.showComponentDialog(
      UserPublicProfileComponent,
      hash,
      { width: '90%' },
      () => {
        // Refresh the list regardless of how the dialog is closed.
        // this.dataSource.getData();
      }
    );
  }

}

export class ToolsDataSource extends ApiDataSource {
  order_by = 'id';
  order = 'asc';
  searchBy = 'title';
  industryFilter = 0;
  archived = "false";

  getData(resetOffset: boolean = false) {
    this.makeRequest('v2/tools', resetOffset, {
      searchBy: this.searchBy,
      industryFilter: this.industryFilter,
      archived: this.archived
    });
  }
}
