import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { UtilsService } from '../../shared/utils.service';
import { ContractorService } from '../contractor.service';
import { NgForm } from '@angular/forms';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { ContractorAccountModel } from 'src/app/models/contractor-account.model';
import {IndustriesModel} from "../../admin/industries/industries.model";


@Component({
  selector: 'app-contractors-details',
  templateUrl: './contractors-details.component.html',
  styleUrls: ['./contractors-details.component.scss']
})
export class ContractorsDetailsComponent implements AfterViewInit {

  industries: IndustriesModel[] = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public app: AppService,
    private api: ApiRequestService,
    public utils: UtilsService,
    public contractorService: ContractorService
  ) {}

  ngAfterViewInit(): void {
    // Get the contractor id from the route params.
    const contractor_id: number = Number(this.route.parent.snapshot.params['contractor_id']);
    if ( !contractor_id ) {
      // Redirect to the contractors list if the id is not present.
      this.router.navigate(['/contractors']);
      return;
    }

    // Get the contractor details.
    this.contractorService.getContractorById(contractor_id);

    // Get industries from the API.
    this.getIndustries();
  }

  private async getIndustries() {
    this.industries = await this.utils.getIndustries();
  }

  onSubmit(form: NgForm) {
    if ( !form.valid ) {
      this.utils.showFormValidationError();
      return;
    }

    this.api.makeRequest('put', `v2/contractors/${this.contractorService.contractor.id}`, this.contractorService.contractor)
    .then((contractor: ContractorAccountModel) => {
      this.contractorService.contractor.apply(contractor);
      this.utils.showToast('The ' + this.utils.getLangTerm('contractors.singular', 'Contractor') + ' was successfully updated.');
    })
    .catch((_error) => {
      this.utils.handleAPIErrors(_error);
    });
  }
}
