import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SitesSelectorComponent } from 'src/app/shared/sites-selector/sites-selector.component';
import { AppService } from '../../app.service';
import { HazardControlModel } from '../../models/hazard-control.model';
import { HazardModel } from '../../models/hazard.model';
import { ApiService } from '../../shared/api.service';
import { UtilsService } from '../../shared/utils.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { FilesRemoveComponent } from 'src/app/files/files-remove/files-remove.component';
import { FileService } from 'src/app/shared/file.service';
import { RISK_LEVEL_OF_CONTROLS } from 'src/app/shared/risk-level-of-controls';
import {
  NetworkedUsersSelectorComponent
} from '../../shared/networked-users-selector/networked-users-selector.component';
import * as moment from 'moment/moment';
import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';
import { CurrentTimezoneStateService } from '../../shared/current-timezone-state.service';
import { HazardsRisksEditComponent } from '../hazards-risks-edit/hazards-risks-edit.component';
import { RiskLevel } from '../../shared/risk-matrix/risk-matrix.component';
import {
  HazardsHierarchyOfControlsComponent
} from '../hazards-hierarchy-of-controls/hazards-hierarchy-of-controls.component';

@Component({
  selector: 'app-hazards-edit',
  templateUrl: './hazards-edit.component.html',
  styleUrls: ['./hazards-edit.component.scss']
})
export class HazardsEditComponent implements OnInit {

  /**
   * Is the form being submitted?
   */
  inProgress = false;

  loc_list: string[] = RISK_LEVEL_OF_CONTROLS;

  search = '';

  newFiles: FileList;

  // Used to extract the date and time from a date/time picker.
  @ViewChild('reviewedAtDateTimePickerRef') reviewedAtDateTimePickerRef: NgxMatDatetimePicker<any>;

  reviewedAtDateTime: moment.Moment;

  selectedTimezone: string = this.cTmzState.getCurrentTimezone();

  constructor(
    public app: AppService,
    private api: ApiService,
    public router: Router,
    public route: ActivatedRoute,
    public utils: UtilsService,
    private cTmzState: CurrentTimezoneStateService,
    @Inject(MAT_DIALOG_DATA) public hazard: HazardModel,
    private dialogRef: MatDialogRef<HazardsEditComponent>,
    private dialog: MatDialog,
    private fileService: FileService
  ) {}

  ngOnInit() {
    if (!this.hazard.id) {
      this.hazard.selected_site_ids = [];
    } else {
      // Convert unix time into moment object
      if ( this.hazard.reviewer_date ) {
        this.reviewedAtDateTime = moment.unix(this.hazard.reviewer_date);
      }
    }
    if (typeof this.hazard.controls == 'string') {
      this.hazard.controls = JSON.parse(this.hazard.controls);
    }
  }

  find() {
    this.api
      .laravelApiObservable('get', 'hazards/' + this.hazard.id)
      .subscribe((response) => (this.hazard = response.data));
  }

  onRemoveFiles() {
    const dialogRef = this.dialog.open(FilesRemoveComponent, {
      data: this.hazard.files,
      width: '700px'
    });
    dialogRef.afterClosed().subscribe(() => this.find());
  }

  onSelectSites() {
    this.utils.showComponentDialog(
      SitesSelectorComponent,
      {
        selected: this.hazard.selected_site_ids,
        multiple: true
      },
      {
        width: '1024px'
      },
      (results) => {
        if (typeof results !== 'undefined') {
          this.hazard.selected_site_ids = results;
        }
      }
    );
  }

  onSelectSite() {
    this.utils.showComponentDialog( SitesSelectorComponent, {
        selected: [this.hazard.site_id],
        multiple: false
      }, {
        width: '1024px'
      }, (results) => {
        if (typeof results !== 'undefined') {
          this.hazard.site_id = results;
        }
      }
    );
  }

  async onSubmit(form: NgForm) {
    if (this.inProgress) {
      return;
    }

    if (!form.valid || !this.hasValidControls()) {
      this.utils.showModal('Error', 'Please make sure you enter all fields with valid information.');
      return;
    }

    this.inProgress = true;

    // Try to upload the files.
    try {
      this.hazard.files = await this.fileService.createManyFromForm(this.newFiles);
    } catch (error) {
      // Reset the in progress status when the file uploads fail.
      this.inProgress = false;
      return;
    }

    if ( this.hazard.id ) {
      // Get unix date/time from moment
      if ( this.reviewedAtDateTime ) {
        const dateToSave = moment.tz(this.reviewedAtDateTime.format('M/D/YYYY, h:mm:ss a'), 'M/D/YYYY, h:mm:ss a', this.selectedTimezone);
        this.hazard.reviewer_date = dateToSave.unix();
      } else {
        this.hazard.reviewer_date = null;
      }

      return this.update();
    }
    this.create();
  }

  create() {
    this.api.laravelApiRequest(
      'post',
      'hazards',
      this.hazard,
      {},
      () => {
        this.utils.showToast('The Hazard/Risk was created.');
        this.dialogRef.close(true);
        this.inProgress = false;
      },
      (error: HttpErrorResponse) => {
        this.utils.showModal('Error', error.message);
        this.inProgress = false;
      }
    );
  }

  update() {
    this.api.laravelApiRequest(
      'put',
      'hazards/' + this.hazard.id,
      this.hazard,
      {},
      (response) => {
        const site_count_msg = (response.meta.associated_site_count && response.meta.associated_site_count > 0 && ((this.hazard.selected_site_ids && this.hazard.selected_site_ids.length > 0) || this.hazard.copy_to_all_sites)) ?
          ` and Copied to ${response.meta.associated_site_count} ${this.utils.getLangTerm('parent-child-sites-combined.plural', 'Sites').toLowerCase()}` :
          '';
        this.utils.showToast(`The Hazard/Risk was updated${site_count_msg}.`);
        this.inProgress = false;
        this.newFiles = null;
        this.find();
      },
      (error: HttpErrorResponse) => {
        this.utils.showModal('Error', error.message);
        this.inProgress = false;
      }
    );
  }

  /**
   * Validate hazard controls.
   */
  hasValidControls() {
    if (this.hazard.controls.length > 0) {
      let hasValidControls = true;
      this.hazard.controls.forEach((control) => {
        if (!control.control || !control.loc) {
          hasValidControls = false;
        }
      });
      return hasValidControls;
    }
    return true;
  }

  onAddControl() {
    this.hazard.controls.push(new HazardControlModel());
  }

  onRemoveControl(i: number) {
    this.hazard.controls.splice(i, 1);
  }

  getRiskAssessmentColorChange(likelihood: number, severity: number) {
    const riskAssessment = this.utils.getRiskAssessmentText(
      likelihood,
      severity
    );

    if (['Very Low'].find((value) => value === riskAssessment)) {
      return 'success';
    }

    if (['Low'].find((value) => value === riskAssessment)) {
      return 'info';
    }

    if (['Moderate'].find((value) => value === riskAssessment)) {
      return 'warning';
    }

    if (['High'].find((value) => value === riskAssessment)) {
      return 'warning-secondary';
    }

    if (['Critical'].find((value) => value === riskAssessment)) {
      return 'danger';
    }

    return 'danger';
  }

  onRemove() {
    this.utils.showModal(
      'Archive Hazard/Risk',
      'Are you sure you want to archive this Hazard/Risk?',
      () => {
        this.api.laravelApiRequest(
          'delete',
          'hazards/' + this.hazard.id + (this.hazard.site_id ? '?site_id=' + this.hazard.site_id : ''),
          {},
          {},
          () => {
            this.utils.showToast('The Hazard/Risk was archived.');
            this.dialogRef.close(true);
            this.inProgress = false;
          },
          (error) => {
            this.utils.showModal('Error', error.message);
          }
        );
      }
    );
  }

  onSelectReviewer() {
    this.utils.showComponentDialog(
      NetworkedUsersSelectorComponent,
      {
        multiple: false,
        selected: [this.hazard.reviewer_id],
        selectedAccountId: this.app.account.id,
        visitors_from_all_sites: true
      },
      {},
      (userId: number) => {

        if ( typeof userId == 'undefined' ) {
          return;
        }

        this.hazard.reviewer_id = userId;
      }
    );

  }

  /**
   * Updates the view matrix based on the type parameter.
   *
   * @param {string} [type='Initial'] - The type of view matrix to update. Valid values are 'Initial' and 'Residual'.
   *
   * @return {void}
   */
  onViewMatrix(type: string = 'Initial') {
    type RiskLevelType = 1 | 2 | 3 | 4 | 5;
    let likelihood_selected: RiskLevelType = 2;
    let severity_selected: RiskLevelType = 2;

    switch (type) {
      case 'Initial':
        likelihood_selected = this.hazard.ira_likelihood;
        severity_selected = this.hazard.ira_severity;
        break;
      case 'Residual':
        likelihood_selected = this.hazard.rra_likelihood;
        severity_selected = this.hazard.rra_severity;
        break;
      default:
        return;
    }

    this.utils.showComponentDialog(
      HazardsRisksEditComponent,
      {
        type: type,
        selected: {
          likelihood: likelihood_selected,
          severity: severity_selected
        } as RiskLevel
      },
      {},
      (response: RiskLevel) => {

        if ( typeof response == 'undefined' ) {
          return;
        }

        if ([1, 2, 3, 4, 5].includes(response.likelihood) && [1, 2, 3, 4, 5].includes(response.severity)) {
          if(type == 'Initial') {
            this.hazard.ira_likelihood = response.likelihood as RiskLevelType;
            this.hazard.ira_severity = response.severity as RiskLevelType;
          } else if(type == 'Residual') {
            this.hazard.rra_likelihood = response.likelihood as RiskLevelType;
            this.hazard.rra_severity = response.severity as RiskLevelType;
          }
        }
      }
    );
  }


  /**
   * Opens a component dialog to display the hierarchy of controls related to hazards.
   *
   * @returns {void}
   */
  onViewHierarchyOfControl() {
    this.utils.showComponentDialog(
      HazardsHierarchyOfControlsComponent,
      {},
      {width: '1200px'},
      (results) => {
        // No action needed.
    });
  }

}
